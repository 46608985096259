import {
  get,
  post
}
  from '../common/axiosHttp'


// 编辑角色
export const roleEdit = params => post("/role/update", params);

// 新增角色
export const roleAdd = params => post("/role/add", params);